import { Component, OnInit } from '@angular/core';
import { CommonFunctionService } from 'src/app/services/commonfunction.service';
import { ROUTES, QCROUTES ,INSPROUTES } from './menu-items';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  user: any;
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }
  constructor(
    private CommonService: CommonFunctionService,
  ) {
    // const TKN = localStorage.getItem(this.CommonService.tokenname);
    // if (TKN && TKN !== null) {
    //   this.user = JSON.parse(this.CommonService.Decrypt(TKN, this.CommonService.tokenname));
    // }
    this.user =  JSON.parse(localStorage.getItem('Role_id'));

  }
  // End open close
  ngOnInit() {
    this.sidebarnavItems =(this.user[0].Role_id === 101) ? ROUTES.filter(sidebarnavItem => sidebarnavItem):(this.user[0].Role_id === 102) ? QCROUTES.filter(sidebarnavItem => sidebarnavItem):
    INSPROUTES.filter(sidebarnavItem => sidebarnavItem);
  }
}
