import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BlankComponent } from '../layouts/blank/blank.component';
import { NotfoundComponent } from './404/not-found.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { TranslateModule } from '@ngx-translate/core';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
export const AuthenticationRoutes: Routes = [


  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        children: [
          {
            path: '404',
            component: NotfoundComponent
          },
          {
            path: '',
            component: LoginComponent
          },
          {
            path: 'signup',
            component: SignupComponent
          },
          {
            path: 'restpassword',
            component: ResetpasswordComponent
          },
          {
            path: 'forgetpassword',
            component: ForgetpasswordComponent
          },
        ]
      }
    ]
  },
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule.forChild(AuthenticationRoutes),
    AngularMultiSelectModule
  ],
  declarations: [
    NotfoundComponent,
    LoginComponent,
    SignupComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent
  ]
})
export class AuthenticationModule {}
